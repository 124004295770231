import * as React from "react";
import { css, useTheme, withTheme } from "@delight-js/react";
import PropTypes from "prop-types";

const Label = withTheme(({ theme, children, disabled = false, ...props }) => (
  <label
    {...props}
    css={css`
      position: relative;
      border: 0;
      display: block;

      span {
        position: absolute;
        left: 0;
        top: 1.2em;
        transition: transform 0.2s, color 0.2s;
        transform-origin: 0 0;
        pointer-events: none;
      }
      &:focus-within {
        span {
          color: $color-spot1;
        }
      }

      input {
        transition: border-color 0.25s;

        &:focus,
        &:not(:placeholder-shown) {
          + span {
            transform: translate3d(0, -23px, 0) scale(0.5);
          }
        }

        &:focus {
          border-color: $color-spot1;
        }
      }
    `(theme)}
    style={
      disabled
        ? {
            color: "var(--color-neutral)",
            cursor: "not-allowed",
          }
        : {}
    }
  >
    {children}
  </label>
));

function TextInputFormik({ field, className, label, required, ...props }) {
  const theme = useTheme();
  const { placeholder, ...inputProps } = field;
  return (
    <Label className={className}>
      <input
        {...inputProps}
        placeholder={" "}
        required={required}
        css={css`
          display: block;
          width: 100%;
          background: transparent;
          border: 0;
          border-bottom: 2px solid $color-text;
          padding: 1em 0;
          outline: 0;
          $textBody;
          $textBody1;

          &:disabled {
            color: $color-neutral;
            border-color: $color-neutral;
            cursor: not-allowed;
          }
        `(theme)}
      />
      <span>{label}</span>
    </Label>
  );
}

TextInputFormik.propTypes = {
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
};
TextInputFormik.defaultProps = {
  placeholder: " ",
};

export default withTheme(TextInputFormik);
